import { useEffect, useRef, useState, useContext } from "react";
import styles from "./styles.module.css";
import { camera, compass, contextGlobal } from "App";
import Button_Close from "components/Button_Close";
import UI_Slider, { uiSliderSetIndex } from "components/UI_Slider";
import { modeList } from "datas";
import * as AFRAME from "aframe";
import {
  vertexShader1,
  fragmentShader1,
  fragmentShader2,
} from "assets/shaders";
import gsap from "gsap";
import imgIcon from "./images/icon_doc.svg";
import { baseSpotsPath } from "datas";

export default () => {
  const global = useContext(contextGlobal);
  const refDebug = useRef(null);
  const refScene = useRef(null);
  const refEffectParent = useRef(null);
  const refEffectInner = useRef(null);
  const refEffect = useRef(null);
  const refContainer = useRef(null);
  const refCamera3D = useRef(null);
  const refVideo = useRef(null);
  const refPhotoName = useRef(null);
  const refListText = useRef(null);
  const refTextArea = useRef(null);
  const refTextTarget = useRef(null);
  const [stSliderNameList, stStmSliderNameList] = useState(null);
  const [stMeshs, setStMeshs] = useState([]);
  const [stViewDetailBtn, setStViewDetailBtn] = useState(false);
  const [stViewDetailTexts, setStViewDetailTexts] = useState([]);
  const [stViewTextArea, setStViewTextArea] = useState(false);
  const [stViewTextAreaMin, setStViewTextAreaMin] = useState(false);
  const [stTargetTextIndex, setStTargetTextIndex] = useState(null);
  const [stUISActive, setStUISActive] = useState(false);
  const [stMeshEffect, setStMeshEffect] = useState(null);
  const textureLoader = new AFRAME.THREE.TextureLoader();
  let flgInit = false;

  //---useEffects
  {
    //初期化処理
    useEffect(() => {
      handleInitScene();
    }, []);
    //モード切替時処理
    useEffect(() => {
      handleModeCheck();
    }, [global.mode.val]);
    //スポット変更時処理
    useEffect(() => {
      handleInitArPhotos();
    }, [global.spotID.val, global.mode.val, stMeshEffect]);
    //横向き時処理
    useEffect(() => {
      handleLandscape();
    }, [global.landscape.val]);
    //スライダー項目更新処理
    useEffect(() => {
      handleUiSliderActiveCheck();
    }, [global.mode.val, stSliderNameList]);
    //詳細テキスト切替時処理
    useEffect(() => {
      handleTargetTextChange();
    }, [stTargetTextIndex]);
    //言語切替時処理
    useEffect(() => {
      handleChangeLan();
    }, [
      global.mode.val,
      global.lan.val,
      global.spotID.val,
      global.mode.val,
      stMeshs,
    ]);
  }

  //---handles

  /**
   * 初期化処理
   */
  function handleInitScene() {
    if (!refScene.current || flgInit) return;
    flgInit = true;
    //コンパス情報で北を軸にするイベント登録
    let rotationCache;
    let landscapeCache;
    compass.setCustomEvent(() => {
      if (!rotationCache || Math.abs(rotationCache - compass.degrees) > 20) {
        //北にコンテンツが向くように回転調整
        //前回から20度以上動かした場合だけ再計算（コンパス情報はフラフラしてるので）
        rotationCache = compass.degrees;
        const rotY =
          rotationCache + refCamera3D.current.getAttribute("rotation").y + 360;
        refContainer.current.setAttribute("rotation", { y: rotY });
        refEffectParent.current.setAttribute("rotation", { y: rotY });
      }
      //横向きにしたときも再計算
      if (landscapeCache != compass.flgLandscape) {
        rotationCache = null;
      }
      landscapeCache = compass.flgLandscape;
      refDebug.current.innerHTML =
        "alpha:" +
        compass.alpha +
        "<br>" +
        "beta:" +
        compass.beta +
        "<br>" +
        "gamma:" +
        compass.gamma;
    });
    //エフェクトの初期設定
    AFRAME.registerComponent("effect", {
      init: function () {
        const meshEffect = this.el.getObject3D("mesh");
        setStMeshEffect(meshEffect);
        meshEffect.material = new AFRAME.THREE.ShaderMaterial({
          uniforms: {
            opacity: { value: 1.0 },
            fade: { value: 1.0 },
            move: { value: -1.0 },
            tFade: { value: textureLoader.load("images/tFade2.png") },
            bgColor: { value: { r: 1.0, g: 1.0, b: 1.0 } },
          },
          vertexShader: vertexShader1,
          fragmentShader: fragmentShader2,
          side: 1,
        });
        meshEffect.material.transparent = true;
      },
    });
  }
  /**
   * モード切替時処理
   */
  function handleModeCheck() {
    if (global.mode.val != modeList[4]) {
      if (refContainer.current) {
        refContainer.current.setAttribute("visible", "false");
      }
      camera.stop();
      return;
    }
    if (refContainer.current) {
      refContainer.current.setAttribute("visible", "true");
    }
    if (refVideo.current) {
      camera.setVideo(refVideo.current).then(() => {
        camera.getUserMediaCamera();
      });
    }
  }
  /**
   * AR表示切化処理
   */
  function handleInitArPhotos() {
    // ARスポットかチェック
    if (
      global.mode.val != modeList[4] ||
      !global.spotID.val ||
      global.spots.val[global.spotID.val].type != "AR"
    )
      return;

    // スライダー表示用の名前配列作成
    let nameList = [];
    global.spots.val[global.spotID.val].arDatas.map((data) => {
      if (data.name) nameList.push(data.name);
    });

    //設定名が複数ならスライダーセットアップ
    if (nameList.length > 1) {
      stStmSliderNameList(nameList); //UIスライダーの区切り表示設定
      const arDefaultIndex = global.spots.val[global.spotID.val].arDefaultIndex;
      if (arDefaultIndex) {
        if (uiSliderSetIndex) uiSliderSetIndex(arDefaultIndex); //UIスライダーの区切り位置初期化
        const defaultVal =
          (1 / global.spots.val[global.spotID.val].arDatas.length) *
          arDefaultIndex;
        handleSliderChange(defaultVal);
      }
    }
    //設定名が単体なら詳細テキストボタン表示・スライダー非表示
    if (nameList.length == 1) {
      stStmSliderNameList(null);
      setStViewDetailBtn(true);
      handleChangeBtnName(global.spots.val[global.spotID.val].arDatas[0].name);
      setStTargetTextIndex(0);
    }
    //設定名がなしなら詳細テキストボタン・スライダー非表示
    if (!nameList.length) {
      stStmSliderNameList(null);
      setStViewDetailBtn(false);
    }

    // スライダーと連動するテキスト表示一覧
    let textList = [];
    global.spots.val[global.spotID.val].arDatas.map((data) => {
      if (data.text) return textList.push(data.text);
    });
    setStViewDetailTexts(textList);

    // すでに写真がある場合は削除
    if (refContainer.current && refContainer.current.children.length) {
      [...refContainer.current.children].map((child) => {
        refContainer.current.removeChild(child);
      });
    }
    // 写真の枚数分 配置と初期化設定（カスタムシェーダー組込）
    const meshs = [];
    const tFade = textureLoader.load("images/tFade.png");
    global.spots.val[global.spotID.val].arDatas.map((data, idx) => {
      if (!data.photo) return;
      //aframeオブジェクトの削除処理が非同期なので再表示時エラーが出る、対策でIDにタイムスタンプを付加
      const id =
        global.spots.val[global.spotID.val].path +
        "-" +
        idx +
        "-" +
        new Date().getTime();
      const plane = document.createElement("a-plane");
      plane.setAttribute(id, "");

      if (textList.length) {
        plane.setAttribute("visible", "false");
      }
      plane.setAttribute("width", data.photo.width);
      plane.setAttribute("height", data.photo.height);
      plane.setAttribute("position", data.photo.pos);
      plane.setAttribute("rotation", data.photo.rot);
      refContainer.current.appendChild(plane);
      AFRAME.registerComponent(id, {
        init: function () {
          //AFrameの3Dオブジェクトはinit後、meshにアクセス可能になる
          const arDefaultIndex =
            global.spots.val[global.spotID.val].arDefaultIndex;
          const mesh = this.el.getObject3D("mesh");
          meshs[idx] = mesh;
          const spotPath = global.spots.val[global.spotID.val].path + "/";
          let tPhoto = null;
          let tmpPath = baseSpotsPath;
          if (data.photo && data.photo.src) {
            const lanImgPath = data.photo["src_" + global.lan.val];
            if (lanImgPath) {
              tmpPath += spotPath + lanImgPath;
            } else {
              tmpPath += spotPath + data.photo.src;
            }
          } else {
            tmpPath += "frame.png";
          }
          tPhoto = textureLoader.load(tmpPath);
          plane.setAttribute("visible", "true");
          let opacity = 0.0;
          if (idx === arDefaultIndex) {
            opacity = 1.0;
          }
          if (textList.length <= 1) {
            opacity = 1.0;
          }
          mesh.material = new AFRAME.THREE.ShaderMaterial({
            uniforms: {
              opacity: { value: opacity },
              fade: { value: 1.0 },
              tFade: { value: tFade },
              tPhoto: { value: tPhoto },
            },
            vertexShader: vertexShader1,
            fragmentShader: fragmentShader1,
          });
          mesh.material.transparent = true;
          setStMeshs([...meshs]);
        },
        // remove: function () {
        //   this.el.removeObject3D(id);
        // },
      });
    });

    if (stMeshEffect) {
      refEffectInner.current.setAttribute(
        "rotation",
        global.spots.val[global.spotID.val].arDatas[0].photo.rot
      );
      stMeshEffect.material.uniforms.move.value = -1.0;
      stMeshEffect.material.uniforms.opacity.value = 1.0;
      gsap.to(stMeshEffect.material.uniforms.move, {
        value: 0.2,
        duration: 8,
        delay: 1,
        ease: "sine.out",
        overwrite: true,
        repeat: 1,
      });
      gsap.to(stMeshEffect.material.uniforms.opacity, {
        value: 0.0,
        duration: 8,
        delay: 1,
        ease: "sine.out",
        overwrite: true,
        repeat: 1,
      });
    }
  }
  /**
   * スマホ横向き時処理
   */
  function handleLandscape() {
    //横向きの時は写真スケールを大きく調整
    if (refContainer.current && refContainer.current.children.length) {
      [...refContainer.current.children].map((child) => {
        if (global.landscape.val) {
          child.setAttribute("scale", "2.8 2.8 1");
        } else {
          child.setAttribute("scale", "1 1 1");
        }
      });
    }
  }
  /**
   * 言語切替時処理
   */
  function handleChangeLan() {
    if (global.mode.val != modeList[4] || !global.spots || !global.spotID)
      return;
    //言語用画像があれば切替
    global.spots.val[global.spotID.val].arDatas.map((data, idx) => {
      if (!data.photo || !data.photo.src) return;
      let imgPath = data.photo.src;
      if (data.photo["src_" + global.lan.val]) {
        imgPath = data.photo["src_" + global.lan.val];
      }
      if (stMeshs[idx] && stMeshs[idx].material.uniforms) {
        const tmpPath =
          baseSpotsPath +
          global.spots.val[global.spotID.val].path +
          "/" +
          imgPath;
        stMeshs[idx].material.uniforms.tPhoto.value =
          textureLoader.load(tmpPath);
      }
    });
  }

  /**
   * UI AR表示終了処理
   */
  function handleClose() {
    //Aframeは停止方法見当たらないため非表示で負荷軽減
    refContainer.current.setAttribute("visible", "false");
    camera.stop();
    global.mode.set(modeList[1]); //マップ画面へ戻る
  }
  /**
   * UI スライダー表示 ON/OFF処理
   */
  function handleUiSliderActiveCheck() {
    if (
      global.mode.val == modeList[4] &&
      stSliderNameList &&
      stSliderNameList.length > 0 &&
      global.spots.val[global.spotID.val].slider
    ) {
      setStUISActive(true);
    } else {
      setStUISActive(false);
    }
  }
  /**
   * UI スライダー変更処理
   * @param {Number} val スライダー設定値
   */
  function handleSliderChange(val) {
    if (!global.spots.val[global.spotID.val]) return;
    const sliderFlg = global.spots.val[global.spotID.val].slider;
    const arDatas = global.spots.val[global.spotID.val].arDatas;
    if (!sliderFlg || !arDatas[0].name) return; //スライダー用情報がない場合は中止
    //スライダーのメモリ単位に写真のフェード値を変更
    const separator =
      1 / (global.spots.val[global.spotID.val].arDatas.length - 1);
    const idxView = parseInt(val / separator); //表示写真インデックス計算
    const idxText = Math.round(val / separator); //表示テキストインデックス計算
    setStTargetTextIndex(idxText); //表示する詳細テキスト設定
    const textList = arDatas.map((data) => data.text);
    if (textList[idxText] && textList[idxText].ja) {
      setStViewDetailBtn(true);
    } else {
      setStViewDetailBtn(false);
    }
    const nameObj = arDatas[idxText].name;
    handleChangeBtnName(nameObj);
    const opacity = val / separator - Math.floor(val / separator);
    if (stMeshs.length) {
      stMeshs.map((mesh, idx) => {
        if (!mesh || !mesh.material) return;
        let tmpVal = 0;
        if (idxView == idx) {
          tmpVal = 1 - opacity;
        }
        if (idxView + 1 == idx) {
          tmpVal = opacity;
        }
        gsap.to(mesh.material.uniforms.opacity, {
          value: tmpVal,
          duration: 1,
          ease: "sine.out",
          overwrite: true,
        });
      });
    }
  }
  /**
   * UI ボタン表示変更
   */
  function handleChangeBtnName(nameObj) {
    refPhotoName.current.innerHTML = `
    <span class="ja">${nameObj.ja}&nbsp;:&nbsp;詳細</span>
    <span class="en">${nameObj.en}&nbsp;:&nbsp;Detailed</span>
    <span class="ko">${nameObj.ko}&nbsp;:&nbsp;상세</span>
    <span class="tw">${nameObj.tw}&nbsp;:&nbsp;詳細</span>
    <span class="cn">${nameObj.cn}&nbsp;:&nbsp;详细</span>`;
  }
  /**
   * UI 詳細テキスト展開処理
   */
  function handleTextOpen() {
    setStViewTextArea(true);
  }
  /**
   * UI 詳細テキスト閉じる処理
   */
  function handleTextClose() {
    setStViewTextArea(false);
  }
  /**
   * UI 詳細テキスト切替時処理
   */
  function handleTargetTextChange() {
    if (!refTextTarget.current) return;
    if (
      refTextArea.current.offsetHeight >
      refTextTarget.current.offsetHeight * 1.5
    ) {
      setStViewTextAreaMin(true);
    } else {
      setStViewTextAreaMin(false);
    }
  }

  return (
    <div
      className={`${styles.screen} ${
        global.mode.val == modeList[4] ? styles.active : ""
      }`}
    >
      <video ref={refVideo} className={styles.arVideo} playsInline></video>
      <div ref={refDebug} className={styles.debug}>
        debug
      </div>
      <div className={styles.btnArea}>
        <Button_Close onClick={handleClose} text="AR表示終了" />
      </div>
      <a-scene
        ref={refScene}
        main_scene
        xr-mode-ui="enabled: false"
        vr-mode-ui="enabled: false"
      >
        <a-entity ref={refEffectParent} rotation="0 0 0">
          <a-entity ref={refEffectInner} rotation="0 0 0">
            <a-sphere
              effect
              ref={refEffect}
              position="0 0 0"
              rotation="90 0 0"
              radius="10"
              color="#EF2D5E"
            ></a-sphere>
          </a-entity>
        </a-entity>
        <a-entity ref={refContainer}></a-entity>
        <a-camera
          ref={refCamera3D}
          position="0 0 0"
          look-controls="touchEnabled:false"
          wasd-controls="enabled: false"
        ></a-camera>
      </a-scene>
      <div
        className={`${styles.btnText} ${stViewDetailBtn ? styles.active : ""}`}
      >
        <span onClick={handleTextOpen}>
          <span className={styles.inner}>
            <img className={styles.icon} src={imgIcon} />
            <span ref={refPhotoName} className="selectLan"></span>
          </span>
        </span>
      </div>
      <div
        ref={refTextArea}
        className={`${styles.textArea} ${
          stViewTextArea && stViewDetailBtn ? styles.active : ""
        }`}
      >
        {(() => {
          if (stViewDetailTexts && stViewDetailTexts.length > 0) {
            return (
              <div
                className={`${styles.inner} ${
                  stViewTextAreaMin ? styles.min : ""
                }`}
              >
                <div onClick={handleTextClose} className={styles.close}>
                  <span></span>
                </div>
                <ul ref={refListText}>
                  {stViewDetailTexts.map((text, idx) => (
                    <li
                      key={idx}
                      className={stTargetTextIndex == idx ? styles.active : ""}
                    >
                      {(() => {
                        if (stTargetTextIndex == idx) {
                          return (
                            <div className="scroll">
                              <div ref={refTextTarget} className="selectLan">
                                <p className="ja">{text.ja}</p>
                                <p className="en">{text.en}</p>
                                <p className="ko">{text.ko}</p>
                                <p className="tw">{text.tw}</p>
                                <p className="cn">{text.cn}</p>
                              </div>
                            </div>
                          );
                        }
                        if (stTargetTextIndex != idx) {
                          return (
                            <div className="scroll">
                              <div className="selectLan">
                                <p className="ja">{text.ja}</p>
                                <p className="en">{text.en}</p>
                                <p className="ko">{text.ko}</p>
                                <p className="tw">{text.tw}</p>
                                <p className="cn">{text.cn}</p>
                              </div>
                            </div>
                          );
                        }
                      })()}
                    </li>
                  ))}
                </ul>
              </div>
            );
          }
        })()}
      </div>

      <UI_Slider
        onChange={handleSliderChange}
        active={stUISActive}
        list={stSliderNameList}
      />
    </div>
  );
};
