import { useEffect, useState, useContext, useRef } from "react";
import styles from "./styles.module.css";
import { contextGlobal } from "App";
import Button_Close from "components/Button_Close";
import { modeList } from "datas";
import Button_Debug from "components/Button_Debug";

export default () => {
  const global = useContext(contextGlobal);
  //ref
  const refPhoto = useRef();
  const refActiveImg = useRef();
  //写真の表示対象インデックス番号
  const [photoIndex, setPhotoIndex] = useState(0);
  //写真表示 リスト/アイコン/スポット名
  const [viewLoading, setViewLoading] = useState(true);
  const [viewList, setViewList] = useState(null);
  const [viewListIcon, setViewListIcon] = useState(null);
  const [viewName, setViewName] = useState(null);
  const [viewText, setViewText] = useState(null);
  //写真複数枚判定
  const [viewSlideUI, setViewSlideUI] = useState(false);

  //---useEffects
  {
    useEffect(() => {
      setPhotoIndex(0);
      handleUpdateList();
      if (global.spots.val[global.spotID.val]) {
        setViewName(global.spots.val[global.spotID.val].name);
        setViewText(global.spots.val[global.spotID.val].text);
      }
    }, [global.spots.val, global.spotID.val]);
    useEffect(() => {
      handleUpdateList();
    }, [photoIndex]);
    useEffect(() => {
      window.addEventListener("resize", handleUpdatePhotoArea);
      return () => {
        document.removeEventListener("resize", handleUpdatePhotoArea);
      };
    }, []);
    useEffect(() => {
      handleUpdatePhotoArea();
    }, [viewList]);
  }

  //---handles
  function handleUpdatePhotoArea() {
    if (refActiveImg.current && refPhoto.current) {
      if (refActiveImg.current.height) {
        refPhoto.current.style.height = refActiveImg.current.height + "px";
      } else {
        setTimeout(handleUpdatePhotoArea, 250);
      }
    }
  }
  function handleUpdateList() {
    if (!global.spots.val || !global.spotID.val) return;
    const path = global.spots.val[global.spotID.val].path;
    loading();
    global.spots.val[global.spotID.val].photos.length > 1
      ? setViewSlideUI(true)
      : setViewSlideUI(false);
    const tmpList = global.spots.val[global.spotID.val].photos.map(
      (fileName, idx) => (
        <li
          key={`${idx}:${fileName}`}
          className={`scroll ${photoIndex == idx ? styles.active : null}`}
        >
          {photoIndex == idx && (
            <img
              ref={refActiveImg}
              src={`spotsData/${path}/${fileName}`}
              alt="photo"
            />
          )}
          {!photoIndex == idx && (
            <img src={`spotsData/${path}/${fileName}`} alt="photo" />
          )}
        </li>
      )
    );
    setViewList(tmpList);
    const tmpListIcon = global.spots.val[global.spotID.val].photos.map(
      (fileName, idx) => (
        <li
          key={`${idx}:${fileName}`}
          onClick={(e) => {
            setPhotoIndex(Number(e.currentTarget.getAttribute("data-num")));
          }}
          data-num={idx}
          className={photoIndex == idx ? styles.active : null}
        >
          <span>{idx}</span>
        </li>
      )
    );
    setViewListIcon(tmpListIcon);
    function loading() {
      setViewLoading(true);
      const len = global.spots.val[global.spotID.val].photos.length;
      let loadedImg = 0;
      global.spots.val[global.spotID.val].photos.map((fileName) => {
        const img = new Image();
        img.src = `spotsData/${path}/${fileName}`;
        img.onload = () => {
          loadedImg++;
          if (len <= loadedImg) {
            setViewLoading(false);
          }
        };
      });
    }
  }

  function handleClose() {
    global.mode.set(modeList[1]); //マップ画面指定
  }
  function handlePhotoNext() {
    if (!global.spots.val) return;
    let tmpIndex = photoIndex + 1;
    if (tmpIndex >= global.spots.val[global.spotID.val].photos.length) {
      tmpIndex = 0;
    }
    setPhotoIndex(tmpIndex);
  }
  function handlePhotoPrev() {
    if (!global.spots.val) return;
    let tmpIndex = photoIndex - 1;
    if (tmpIndex <= -1) {
      tmpIndex = global.spots.val[global.spotID.val].photos.length - 1;
    }
    setPhotoIndex(tmpIndex);
  }

  return (
    <div
      className={`${styles.screen} 
      ${viewLoading ? styles.loading : ""}
      ${viewSlideUI ? styles.slideUI : ""}
      ${global.landscape.val ? styles.landscape : ""} 
      ${global.mode.val == modeList[3] ? styles.active : ""}`}
    >
      <div className={styles.inner}>
        {global.spots &&
          global.spots.val[global.spotID.val] &&
          global.spots.val[global.spotID.val].type == "AR" && (
            <div className={styles.btnDebug}>
              <Button_Debug
                onClick={() => {
                  global.mode.set(modeList[4]);
                }}
                text={"ARテスト起動"}
              />
            </div>
          )}

        <div className={styles.btnArea}>
          <Button_Close onClick={handleClose} />
        </div>
        <div className={styles.inner2}>
          <div className={`scroll ${styles.inner3}`}>
            <p className={`${styles.name} selectLan`}>
              <span className="ja">{viewName && viewName.ja}</span>
              <span className="en">{viewName && viewName.en}</span>
              <span className="ko">{viewName && viewName.ko}</span>
              <span className="tw">{viewName && viewName.tw}</span>
              <span className="cn">{viewName && viewName.cn}</span>
            </p>
            <div ref={refPhoto} className={`${styles.photos}`}>
              <ul className={`${styles.listPhoto}`}>{viewList && viewList}</ul>
              <div className={styles.ui}>
                <div className={styles.left} onClick={handlePhotoPrev}>
                  <span></span>
                </div>
                <div className={styles.right} onClick={handlePhotoNext}>
                  <span></span>
                </div>
              </div>
              <ul className={styles.listIcon}>{viewListIcon}</ul>
            </div>
            <div className={`${styles.spotText} selectLan`}>
              <span className="ja">{viewText && viewText.ja}</span>
              <span className="en">{viewText && viewText.en}</span>
              <span className="ko">{viewText && viewText.ko}</span>
              <span className="tw">{viewText && viewText.tw}</span>
              <span className="cn">{viewText && viewText.cn}</span>
            </div>
          </div>
        </div>
        <div className={styles.loadingAnime}>
          <span>
            <span className={styles.loadingObj}>
              <span className={styles.obj1}></span>
              <span className={styles.obj2}></span>
              <span className={styles.obj3}></span>
            </span>
            <span className={styles.loadingText}>Loading</span>
          </span>
        </div>
      </div>
    </div>
  );
};
