import { useState } from "react";
import styles from "./styles.module.css";
import imgPin from "./images/vector_pin.svg";

export default (props) => {
  return (
    <a onClick={props.onClick} className={`${styles.btn} ${styles.ar}`}>
      <span className={styles.icon}>
        <img src={imgPin} alt="ピンアイコン" />
      </span>
      <span className="selectLan">
        <span className="ja">AR スタート</span>
        <span className="en">Start AR</span>
        <span className="ko">AR 시작</span>
        <span className="tw">啟用 AR</span>
        <span className="cn">开始 AR</span>
      </span>
    </a>
  );
};
