import { useState, useRef, useEffect } from "react";
import styles from "./styles.module.css";
import imgIcon from "./images/icon.svg";

export default (props) => {
  const refButton = useRef(null);
  useEffect(() => {}, []);

  function handleClick() {
    if (props.onClick) props.onClick();
  }

  return (
    <a ref={refButton} onClick={handleClick} className={styles.button}>
      <div className={styles.inner}>
        <span className={styles.icon}>
          <img src={imgIcon} alt="×" />
        </span>
        <span className="selectLan">
          <span className="ja">マップ全体</span>
          <span className="en">Entire Map</span>
          <span className="ko">전체 지도</span>
          <span className="tw">顯示整個地圖</span>
          <span className="cn">显示整个地图</span>
        </span>
      </div>
    </a>
  );
};
