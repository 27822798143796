import { useState, useRef, useEffect, useCallback, useContext } from "react";
import styles from "./styles.module.css";
import { contextGlobal } from "App";
import imgIconLan from "./images/vector_lan.svg";
import imgIconGuide from "./images/vector_guide.svg";

export default (props) => {
  const global = useContext(contextGlobal);
  const refButtonMenu = useRef(null);
  const refButtonLan = useRef(null);
  const refLanList = useRef(null);
  const [stmViewMenu, setStmViewMenu] = useState(true);

  //useEffects
  useEffect(() => {}, []);

  //handles
  const handleMenuClick = useCallback(() => {
    stmViewMenu ? setStmViewMenu(false) : setStmViewMenu(true);
  }, [stmViewMenu]);

  const handleLanClick = useCallback(() => {
    refLanList.current.classList.toggle(styles.active);
  }, []);

  const handleLanChange = useCallback((changeLan) => {
    global.lan.set(changeLan);
    refLanList.current.classList.remove(styles.active);
  }, []);

  return (
    <>
      <div className={`${styles.menuArea} ${stmViewMenu ? styles.active : ""}`}>
        <div className={styles.menuAreaInner}>
          <div className={styles.buttonLanArea}>
            <a
              ref={refButtonLan}
              className={styles.buttonLan}
              onClick={handleLanClick}
            >
              <img className={styles.icon} src={imgIconLan} />
              <div className="selectLan">
                <p className="ja">日本語</p>
                <p className="en">English</p>
                <p className="ko">한국어</p>
                <p className="tw">繁体字</p>
                <p className="cn">簡体字</p>
              </div>
            </a>
            <ul ref={refLanList} className={styles.lanList}>
              <li
                className="ja"
                onClick={() => {
                  handleLanChange("ja");
                }}
              >
                <span className={global.lan.val === "ja" ? styles.active : ""}>
                  日本語
                </span>
              </li>
              <li
                className="en"
                onClick={() => {
                  handleLanChange("en");
                }}
              >
                <span className={global.lan.val === "en" ? styles.active : ""}>
                  English
                </span>
              </li>
              <li
                className="cn"
                onClick={() => {
                  handleLanChange("cn");
                }}
              >
                <span className={global.lan.val === "cn" ? styles.active : ""}>
                  簡体字
                </span>
              </li>
              <li
                className="tw"
                onClick={() => {
                  handleLanChange("tw");
                }}
              >
                <span className={global.lan.val === "tw" ? styles.active : ""}>
                  繁体字
                </span>
              </li>
              <li
                className="ko"
                onClick={() => {
                  handleLanChange("ko");
                }}
              >
                <span className={global.lan.val === "ko" ? styles.active : ""}>
                  한국어
                </span>
              </li>
            </ul>
          </div>
          <div className={styles.guideArea}>
            <div className={styles.ttl}>
              <img className={styles.icon} src={imgIconGuide} />
              <span className="selectLan">
                <span className="ja">コンテンツガイド</span>
                <span className="en">Content Guide</span>
                <span className="cn">内容指南</span>
                <span className="tw">內容指南</span>
                <span className="ko">콘텐츠 가이드</span>
              </span>
            </div>
            <div className={`scroll`}>
              <div className={styles.guideAreaInner}>
                <div className="selectLan">
                  <div className="ja">
                    <p className={styles.sttl}>言語選択・ガイド表示画面</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide1_ja.png"
                      />
                    </div>
                    <p className={styles.sttl}>権限設定画面</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide2_ja.png"
                      />
                    </div>
                    <p className={styles.sttl}>マップ画面</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide3_ja.png"
                      />
                      <img
                        className={styles.guideImg}
                        src="images/guide4_ja.png"
                      />
                    </div>
                    <p className={styles.sttl}>AR表示画面</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide5_ja.png"
                      />
                    </div>
                  </div>
                  {/* ja */}
                  <div className="en">
                    <p className={styles.sttl}>
                      Language Selection & Guide Display Screen
                    </p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide1_en.png"
                      />
                    </div>
                    <p className={styles.sttl}>Permission Settings Screen</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide2_en.png"
                      />
                    </div>
                    <p className={styles.sttl}>Map Screen</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide3_en.png"
                      />
                      <img
                        className={styles.guideImg}
                        src="images/guide4_en.png"
                      />
                    </div>
                    <p className={styles.sttl}>AR Display Screen</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide5_en.png"
                      />
                    </div>
                  </div>
                  {/* en */}
                  <div className="cn">
                    <p className={styles.sttl}>语言选择与指南显示界面</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide1_cn.png"
                      />
                    </div>
                    <p className={styles.sttl}>权限设置界面</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide2_cn.png"
                      />
                    </div>
                    <p className={styles.sttl}>地图界面</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide3_cn.png"
                      />
                      <img
                        className={styles.guideImg}
                        src="images/guide4_cn.png"
                      />
                    </div>
                    <p className={styles.sttl}>AR显示界面</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide5_cn.png"
                      />
                    </div>
                  </div>
                  {/* cn */}
                  <div className="tw">
                    <p className={styles.sttl}>語言選擇與指南顯示界面</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide1_tw.png"
                      />
                    </div>
                    <p className={styles.sttl}>權限設定界面</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide2_tw.png"
                      />
                    </div>
                    <p className={styles.sttl}>地圖界面</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide3_tw.png"
                      />
                      <img
                        className={styles.guideImg}
                        src="images/guide4_tw.png"
                      />
                    </div>
                    <p className={styles.sttl}>AR顯示界面</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide5_tw.png"
                      />
                    </div>
                  </div>
                  {/* tw */}
                  <div className="ko">
                    <p className={styles.sttl}>언어 선택 및 가이드 표시 화면</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide1_ko.png"
                      />
                    </div>
                    <p className={styles.sttl}>권한 설정 화면</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide2_ko.png"
                      />
                    </div>
                    <p className={styles.sttl}>지도 화면</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide3_ko.png"
                      />
                      <img
                        className={styles.guideImg}
                        src="images/guide4_ko.png"
                      />
                    </div>
                    <p className={styles.sttl}>AR 표시 화면</p>
                    <div className={styles.guideBlock}>
                      <img
                        className={styles.guideImg}
                        src="images/guide5_ko.png"
                      />
                    </div>
                  </div>
                  {/* ko */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        ref={refButtonMenu}
        onClick={handleMenuClick}
        className={`${styles.buttonMenu}  ${stmViewMenu ? styles.active : ""}`}
      >
        <span className={styles.buttonMenuInner}>
          <span>
            <svg
              className={styles.hatena}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
            </svg>
            <svg
              className={styles.close}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </span>
        </span>
      </a>
    </>
  );
};
