import styles from "./styles.module.css";
import { useEffect, useRef } from "react";
import { gps } from "App";
import { gpsArea } from "datas";

export default (props) => {
  const refPin = useRef(null);
  useEffect(() => {
    if (props.data.type === "Photo") {
      refPin.current.classList.add(styles.pinPhoto);
    }
    if (props.data.slider) {
      refPin.current.classList.add(styles.pinSlider);
    }
    if (props.data.lat && props.data.lon) {
      const pinPos = gps.latLonPos(gpsArea, {
        lat: props.data.lat,
        lon: props.data.lon,
      });
      refPin.current.setAttribute(
        "style",
        `top:${pinPos.top}%;left:${pinPos.left}%`
      );
    }
  }, []);
  return (
    <div ref={refPin} className={styles.pin}>
      <div className={styles.inner}>
        <svg
          width="45"
          height="63"
          viewBox="0 0 45 63"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M45 23.5C45 35.9264 28.5 62.5 22.5 62.5C16.5 62.5 0 35.9264 0 23.5C0 11.0736 10.0736 0 22.5 0C34.9264 0 45 11.0736 45 23.5Z"
            fill="#2a518b"
          />
        </svg>
      </div>
    </div>
  );
};
