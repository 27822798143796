import { useState, useRef, useEffect } from "react";
import styles from "./styles.module.css";
import imgIcon from "./images/vector_close.svg";

export default (props) => {
  const refButton = useRef(null);
  useEffect(() => {}, []);

  function handleClick() {
    if (props.onClick) props.onClick();
  }

  return (
    <a ref={refButton} onClick={handleClick} className={styles.button}>
      <div className={styles.inner}>
        {/* <span className={styles.icon}>
          <img src={imgIcon} alt="×" />
        </span> */}
        <span className="selectLan">
          <span className="ja">戻る</span>
          <span className="en">Back</span>
          <span className="ko">뒤로</span>
          <span className="tw">返回</span>
          <span className="cn">返回</span>
        </span>
      </div>
    </a>
  );
};
