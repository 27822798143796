import { useState, useRef, useEffect, useContext } from "react";
import { contextGlobal } from "App";
import styles from "./styles.module.css";

export default (props) => {
  const global = useContext(contextGlobal);

  // refs
  const refLoading = useRef(null);

  // useEffects
  useEffect(() => {
    handleChangeImgs();
  }, [global.loadImgs.val]);
  useEffect(() => {
    handleChangeFlg();
  }, [global.loadFlg.val]);

  //handles
  function handleChangeImgs() {
    if (!global.loadImgs || !global.loadImgs.val.length) return;
    global.loadFlg.set(true);
    let count = 0;
    global.loadImgs.val.map((path) => {
      const image = new Image();
      image.src = path;
      image.onload = () => {
        count++;
        if (count >= global.loadImgs.val.length) {
          console.log(count + "  " + global.loadImgs.val.length);
          global.loadFlg.set(false);
        }
      };
    });
  }
  function handleChangeFlg() {
    if (global.loadFlg && global.loadFlg.val) {
      global.loadFlg.set(true);
    } else {
      global.loadFlg.set(false);
    }
  }

  return (
    <div
      ref={refLoading}
      className={`${styles.loading} ${global.loadFlg.val ? styles.active : ""}`}
    >
      <div className={styles.inner}>
        <span className={styles.loadImg1}>&nbsp;</span>
        <span className={styles.loadImg2}>&nbsp;</span>
        <span className={styles.loadImg3}>&nbsp;</span>
      </div>
    </div>
  );
};
