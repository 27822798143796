import { useState, useEffect, useContext } from "react";
import styles from "./styles.module.css";
import { gps, compass, camera, wakelock, contextGlobal } from "App";
import imgIconCamera from "./images/vector_icon_camera.svg";
import imgIconCompass from "./images/vector_icon_compass.svg";
import imgIconGps from "./images/vector_icon_gps.svg";
import imgIconTime from "./images/vector_icon_time.svg";
import { modeList } from "datas";

export default () => {
  const global = useContext(contextGlobal);
  const [viewCameraOn, setViewCameraOn] = useState(false);
  const [viewGpsOn, setViewGpsOn] = useState(false);
  const [viewCompassOn, setViewCompassOn] = useState(false);

  //---useEffects
  {
    useEffect(() => {}, []);
  }

  //---handles
  function handleCamera(e) {
    camera.getUserMediaCamera(
      (stream) => {
        setViewCameraOn(true);
        camera.stop(); //使えるかどうかのチェックなのですぐ停止する
      },
      (error) => {
        alert("カメラが利用できません");
      }
    );
  }
  function handleGPS(e) {
    gps.getPos(
      () => {
        setViewGpsOn(true);
      },
      () => {
        alert("GPS情報が取得できません");
      }
    );
  }
  function handleCompass(e) {
    compass.start(
      () => {
        setViewCompassOn(true);
      },
      () => {
        alert("方位情報が取得できません");
      }
    );
  }
  function handleStart(e) {
    wakelock.start();
    global.mode.set(modeList[1]);
  }

  return (
    <div
      className={`${styles.screen} ${
        global.mode.val != modeList[0] ? styles.off : ""
      }`}
    >
      <h1 className={styles.ttl}>
        <img
          src="images/title.png"
          width={815}
          height={610}
          alt="タイムスコープ"
        />
      </h1>
      <div className={styles.authority}>
        <div className={styles.bg}>
          <div className={styles.text}>
            <div className="selectLan">
              <p className="ja">
                本コンテンツはスマートフォンで震災時の写真閲覧・AR表示します。
                AR表示には以下の権限許可が必要です。
                権限が不足する場合、写真閲覧のみご利用いただけます。
                <br />
                <span className="blue">
                  ※ブラウザのカメラ権限も許可してください。
                </span>
              </p>
              <p className="en">
                This content allows users to view photos and display AR content
                related to disasters on a smartphone. The following permissions
                are required for AR display. If the necessary permissions are
                not granted, only the photo viewing feature will be available.
                <br />
                <span className="blue">
                  ※Please also allow camera permissions in your browser.
                </span>
              </p>
              <p className="ko">
                이 콘텐츠는 스마트폰에서 지진 재난 시의 사진을 열람하거나 AR
                표시를 제공합니다. AR 표시를 위해서는 아래의 권한 허가가
                필요합니다. 권한이 부족할 경우, 사진 열람 기능만 이용하실 수
                있습니다.
                <br />
                <span className="blue">
                  ※브라우저의 카메라 권한도 허용해 주세요.
                </span>
              </p>
              <p className="tw">
                本內容可在智慧型手機上瀏覽震災時的照片並顯示AR
                顯示AR功能需要以下權限的授予 若權限不足,僅能使用照片瀏覽功能
                <br />
                <span className="blue">※请同时允许浏览器的摄像头权限</span>
              </p>
              <p className="cn">
                本内容可在智能手机上浏览震灾时的照片并显示AR
                显示AR功能需要以下权限的授权 如果权限不足,仅能使用照片浏览功能
                <br />
                <span className="blue">※請同時允許瀏覽器的攝像頭權限</span>
              </p>
            </div>
          </div>
          <ul className={styles.list}>
            <li>
              <a
                className={`${viewCameraOn ? styles.on : ""}`}
                onClick={handleCamera}
              >
                <p className={styles.icon}>
                  <img src={imgIconCamera} alt="カメラ権限" />
                </p>
                <div className={styles.name}>
                  <div className="selectLan">
                    <p className="ja">カメラの利用許可</p>
                    <p className="en">Camera access permission</p>
                    <p className="ko">카메라 사용 권한</p>
                    <p className="tw">相機使用權限</p>
                    <p className="cn">相机使用权限</p>
                  </div>
                </div>
                <div className={styles.switch}></div>
              </a>
            </li>
            <li>
              <a className={viewGpsOn ? styles.on : ""} onClick={handleGPS}>
                <p className={styles.icon}>
                  <img src={imgIconGps} alt="位置情報権限" />
                </p>
                <div className={styles.name}>
                  <div className="selectLan">
                    <p className="ja">位置情報の利用許可</p>
                    <p className="en">Location access permission</p>
                    <p className="ko">위치 정보 사용 권한</p>
                    <p className="tw">位置資訊使用權限</p>
                    <p className="cn">位置信息使用权限</p>
                  </div>
                </div>
                <div className={styles.switch}></div>
              </a>
            </li>
            <li>
              <a
                className={viewCompassOn ? styles.on : ""}
                onClick={handleCompass}
              >
                <p className={styles.icon}>
                  <img src={imgIconCompass} alt="方位情報権限" />
                </p>
                <div className={styles.name}>
                  <div className="selectLan">
                    <p className="ja">方位情報の利用許可</p>
                    <p className="en">Orientation access permission</p>
                    <p className="ko">방위 정보 사용 권한</p>
                    <p className="tw">方位資訊使用權限</p>
                    <p className="cn">方位信息使用权限</p>
                  </div>
                </div>
                <div className={styles.switch}></div>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.start}>
        <a onClick={handleStart}>
          <div className={styles.inner}>
            <img src={imgIconTime} alt="タイムスコープ" />
            <div className="selectLan">
              <p className="ja">コンテンツスタート</p>
              <p className="en">Content start</p>
              <p className="ko">콘텐츠 시작</p>
              <p className="tw">內容開始</p>
              <p className="cn">内容开始</p>
            </div>
          </div>
        </a>
      </div>

      <p className={styles.footerLink}>
        <a href="https://www.city.kobe.lg.jp" target="_blank">
          <img
            src="images/logo_kobe.png"
            alt="神戸市"
            width={135}
            height={31}
          />
        </a>
      </p>
    </div>
  );
};
