import styles from "./styles.module.css";
import { useEffect, useRef, useContext } from "react";
import { gps, contextGlobal } from "App";
import { gpsArea, modeList } from "datas";

export default (props) => {
  const global = useContext(contextGlobal);
  const refPin = useRef(null);
  const path = "spotsData/" + props.data.path;
  const thum = path + "/thum.jpg";
  useEffect(() => {
    if (props.data.type === "Photo") {
      refPin.current.classList.add(styles.pinPhoto);
    }
    if (props.data.slider) {
      refPin.current.classList.add(styles.pinSlider);
    }
    if (props.data.lat && props.data.lon) {
      const pinPos = gps.latLonPos(gpsArea, {
        lat: props.data.lat,
        lon: props.data.lon,
      });
      refPin.current.setAttribute(
        "style",
        `top:${pinPos.top}%;left:${pinPos.left}%`
      );
    }
  }, []);
  function handleClick() {
    console.log(props.index);
    global.spotID.set(String(props.index)); //文字にしないと0の時更新されない。。。
    global.mode.set(modeList[3]);
  }
  return (
    <div ref={refPin} className={`${styles.pin} ${styles.small}`}>
      <div
        className={`${styles.inner} ${props.scale == 2 ? styles.scale2 : ""}`}
      >
        <p className={`${styles.name} selectLan`} onClick={handleClick}>
          <span className="ja">{props.data.name.ja}</span>
          <span className="en">{props.data.name.en}</span>
          <span className="ko">{props.data.name.ko}</span>
          <span className="tw">{props.data.name.tw}</span>
          <span className="cn">{props.data.name.cn}</span>
        </p>

        <div className={styles.thum} onClick={handleClick}>
          <img src={thum} alt="サムネイル" />
        </div>

        {/* {props.data.type === "AR" && (
          <p className={styles.type}>{props.data.type}</p>
        )} */}

        <svg
          onClick={handleClick}
          width="45"
          height="63"
          viewBox="0 0 45 63"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M45 23.5C45 35.9264 28.5 62.5 22.5 62.5C16.5 62.5 0 35.9264 0 23.5C0 11.0736 10.0736 0 22.5 0C34.9264 0 45 11.0736 45 23.5Z"
            fill="#2A518B"
          />
        </svg>
      </div>
    </div>
  );
};
